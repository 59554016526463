(function(){
	'use strict';

	angular.module('aerosApp')
		.directive('resultLoss', [function () {
			return {
				restrict: 'A',
				scope: {
					fiberGroup: "=",
					result: "=",
					wavelength: "=",
					main: "=",
					average: "@"
				},
				templateUrl: '/static/templates/directive/result.html',
				controller: ['$scope', '$element', function ($scope, $element) {
					var fiberGroup = $scope.fiberGroup;
					var result = $scope.result;
					var wavelength = $scope.wavelength;
					var wavelengthKey = "_" + wavelength.value;
					$scope.mantissa = 3;
					$scope.showORL = false;
					$scope.hasAverage=false;
					$scope.hasLoss=false;
/* rfm: This works with result.html and resultsController.js to display the Results on the page.
 * I think we should do more here and less in the results.html 
 * i.e results.html has too many ng-ifs in it.
 * 
 * Or how about having the backend "format" the data in the way we are expecting it here.
 * That may simplify the results page and 
 * allow it to performa better.
 * */
					if (result.lossMeasurements && result.lossMeasurements[wavelengthKey]) {
						var lossMeasurementSet = result.lossMeasurements && result.lossMeasurements[wavelengthKey];

						var lossMeasurement = $scope.main ? lossMeasurementSet.MainToRemote : lossMeasurementSet.RemoteToMain;
						
						if (lossMeasurement) {
							$scope.hasLoss=true;
							$scope.failed = lossMeasurement.status == 'Failed';
							$scope.passed = lossMeasurement.status == 'PassedOrComplete';
							$scope.notApplicable = lossMeasurement.status == 'NotApplicable';
							
							$scope.negative = (lossMeasurement.loss < 0 && (lossMeasurement.outsideGuardBand==true));
							$scope.outsideGuardBand=lossMeasurement.outsideGuardBand;


							$scope.hasAverage=true;
							$scope.value = lossMeasurement.loss;
							if ($scope.average && lossMeasurementSet.Average) {
								$scope.avg = lossMeasurementSet.Average.loss;
								$scope.failed = lossMeasurementSet.Average.status == 'Failed';
								$scope.passed = lossMeasurementSet.Average.status == 'PassedOrComplete';
								$scope.notApplicable = lossMeasurementSet.Average.status == 'NotApplicable';
								$scope.negative = (lossMeasurementSet.Average.loss < 0 && (lossMeasurementSet.Average.outsideGuardBand==true));
								$scope.outsideGuardBand=lossMeasurementSet.Average.outsideGuardBand;
								
							} else {
								$scope.avg = $scope.value;
							}
						}
					}

					if(result.orlLossMeasurements && result.orlLossMeasurements[wavelengthKey]){
						$scope.showORL = true;
						var orlMeasurementSet = result.orlLossMeasurements && result.orlLossMeasurements[wavelengthKey];

						var orlLossMeasurement = $scope.main ? orlMeasurementSet.MainToRemote : orlMeasurementSet.RemoteToMain;
						
						if (orlLossMeasurement) {
							$scope.orlFailed = orlLossMeasurement.status == 'Failed';
							$scope.orlPassed = orlLossMeasurement.status == 'PassedOrComplete';
							$scope.orlNotApplicable = orlLossMeasurement.status == 'NotApplicable';
							$scope.orlNegative = orlLossMeasurement.loss < 0;

							$scope.orlValue = orlLossMeasurement.loss;
							$scope.orlAvg = $scope.orlValue;
							
							/* Not showing average
							 * if ($scope.average && orlMeasurementSet.Average) {
								$scope.orlAvg = orlMeasurementSet.Average.loss;
								$scope.orlFailed = orlMeasurementSet.Average.status == 'Failed';
								$scope.orlPassed = orlMeasurementSet.Average.status == 'PassedOrComplete';
								$scope.orlNotApplicable = orlMeasurementSet.Average.status == 'NotApplicable';
								$scope.orlNegative = orlMeasurementSet.Average.loss < 0;
							} else {
								$scope.orlAvg = $scope.orlValue;
							}
							*/
						}
					}

					var fiberGroupRequiresLoss = $scope.main ? fiberGroup.requiresLoss : fiberGroup.requiresBidirectionalLoss;
					var requiresLoss = fiberGroup.fiberMode == wavelength.mode && fiberGroupRequiresLoss;
					$scope.shouldHave = requiresLoss && !result.lossMeasurements;
				}
			]};
		}]);
})();